import { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Navbar from "../admin-navbar.component";
import "./recipie.css";

const Recipie = props => (
    <tr className="recipie">
        <td><i style={{color:"grey"}}>{props.recipie.meal.toString().replace("3","Main").replace("1","Preps/Sauces").replace("2","Apps").replace("4","Dessert")} / </i> <span className="note"><strong>{props.recipie.name}</strong></span></td>
        <td><button className="bt2" onClick={() => { props.hide(props.recipie._id) }}>{props.recipie.hide.toString().replace("false","Hide").replace("true","Unhide")}</button> | <Link to={"/recipies/edit/" + props.recipie._id}><button className="bt1">Edit</button></Link></td>
    </tr>
)

export default class AdminRecipies extends Component {
    constructor(props) {
        super(props);
        // this.changeAlbum = this.changeAlbum.bind(this);
        this.pullRecipies = this.pullRecipies.bind(this);
        this.hide = this.hide.bind(this);
        this.create = this.create.bind(this);
        this.state = {
            posts: [],
            album: "Personal",
            lastevent: ""
        }

    }

    componentDidMount(){
        this.pullRecipies();
    }

    recipieList() {
        return this.state.posts.map(currentrecipie => {
            return <Recipie recipie={currentrecipie} hide={this.hide} key={currentrecipie._id} />
        });
    }

    hide(id) {
        axios.get(process.env.REACT_APP_API_URL+"/recipie/hide/" + id)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                this.pullRecipies();
            }, 250));
    }

    //  changeAlbum(e) {
    //      console.log(this.state.album)
    //     if(this.state.lastevent !== "")
    //         this.state.lastevent.target.className = "circle";
    //     this.setState({
    //         album: e.target.name,
    //         lastevent: e
    //     });
    //     console.log(this.state.album)
    //     this.pullPosts(e.target.name);  
    //     e.target.className = "view";
    // }

    pullRecipies(){
        // axios.get(process.env.REACT_APP_API_URL+"/recipie/") //technically not filtering owner
        axios.post(process.env.REACT_APP_API_URL+"/recipie/secure/of/adit",{owner: localStorage.getItem('token')})
                .then(response => {
                    this.setState({ posts: response.data });
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                  //  window.location = "/access";
                });
        }

    create(e){
        const recipie = {
            name: "New Recipie",
            owner: localStorage.getItem('token'),
            cuisine: "General",
            meal : 3,// 0: All 1: Sauce and Preps 2: Appetizer 3: Main 4: Dessert 
            time : 25,
            steps : [],
            ingreds : [],
            outsideLinks : [],
            videoLinks : [],
            serving : "1 Thing",
            cal : 500,
            description : ""
        };
        axios.post(process.env.REACT_APP_API_URL+"/recipie/add/", recipie)
        .then(res => setTimeout(() => { console.log(res.data); window.location = "/recipies"; }, 250));
        // this.showNotifications();
    }

    render() {
        return (
            <div className="container-fluid">
                <Navbar />            
                <title>Your Recipies</title>
                <h2 className="adBlogTitle" style={{color:"white"}}>Your Recipies</h2>
                <input
                                    type="submit"
                                    value="Create"
                                    className="bt1"
                                    onClick={this.create}
                                />
                {/* <table className="table table-responsive"> //not enogh time to make efficient
                    <thead>
                        <tr>
                            <td>
                                <button type="button" name="Personal" className="circle" onClick={this.changeAlbum}>Personal</button></td>
                                <td><button type="button" name="Professional" className="circle" onClick={this.changeAlbum}>Professional</button></td>
                
                        </tr>
                    </thead>
                </table> */}
                <table className="table table-lg table-responsive-lg  table-hover">
                <tbody>
               {this.recipieList()}
</tbody>
</table>
            </div>
        )
    }
}