import { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import "./edit-note.css";
import EditIcon from '@mui/icons-material/Edit';

import { ToggleButton } from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';

// import { useHistory } from "react-router-dom";
// import icon from "../favicon.ico";
// import ReactNotifications from 'react-browser-notifications';

export default class EditExercise extends Component {
    constructor(props) {
        super(props);

        this.onChangeTopic = this.onChangeTopic.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeDetails = this.onChangeDetails.bind(this);
        this.onChangeNext = this.onChangeNext.bind(this);
        this.onChangeComplete = this.onChangeComplete.bind(this);
        this.onChangeArchive = this.onChangeArchive.bind(this);
        this.hide = this.hide.bind(this);
        this.bringUp = this.bringUp.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        // this.showNotifications = this.showNotifications.bind(this);
        // this.handleClick = this.handleClick.bind(this);
        this.state = {
            topic: '',
            details: '',
            nextTil: 0,
            complete: false,
            date: new Date(),
            archive: ""
        }
    }

    componentDidMount() {
        //right before displays component
        // axios.get(process.env.REACT_APP_API_URL+"/admin/notes/" + this.props.match.params.id)
        //     .then(response => {
        //         var val = "";
        //         if (response.data.archive === true)
        //             val = "Unarchive";
        //         else    
        //             val = "Archive";
        //         this.setState({
        //             topic: response.data.topic,
        //             date: new Date(response.data.date),
        //             details: response.data.details,
        //             nextTil: response.data.nextTil,
        //             complete: response.data.complete,
        //             archive: val
        //         });
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     })
        var note =  JSON.parse(localStorage.getItem("notes")).filter(obj=> (obj._id === this.props.kid))[0];
            var val = "";
            if (note.archive === true)
                val = "Unarchive";
            else    
                val = "Archive";
            this.setState({
                topic: note.topic,
                date: new Date(note.date),
                details: note.details,
                nextTil: note.nextTil,
                complete: note.complete,
                archive: val
            });
    }

    onChangeTopic(e) {
        this.setState({
            topic: e.target.value
        });
    }
    onChangeDetails(e) {
        this.setState({
            details: e.target.value
        });
    }

    onChangeDate(date) {
        this.setState({
            date: date
        });
    }

    bringUp() {
        this.setState({
            date: new Date()
        });
    }

    onChangeNext(e) {
        this.setState({
            nextTil: e.target.value 
        });
    }

    onChangeComplete(e) {
        e.preventDefault()
        const note = {
            topic: this.state.topic,
            date: this.state.date,
            details: this.state.details,
            nextTil: this.state.nextTil,
            complete: true,
            archive: this.state.archive === "Unarchive",
            owner: localStorage.getItem('token')
        };
        var kid = this.props.kid;
        if(navigator.onLine){
        axios.post(process.env.REACT_APP_API_URL+"/admin/notes/update/" + this.props.kid, note)
            // .then(res => setTimeout(() => { console.log(res.data); }, 250));
            // .then(res => setTimeout(() => { var s = "" }, 250));
        }
        var noted =  JSON.parse(localStorage.getItem("notes")).filter(obj=> (obj._id === kid))[0];
        var noteString = JSON.stringify(noted)
        var notes = localStorage.getItem("notes")
        // console.log(JSON.parse(localStorage.getItem('notes')));
        noted.topic = this.state.topic;
        noted.date = this.state.date;
        noted.details = this.state.details;
        noted.nextTil = this.state.nextTil;
        noted.complete = true;
        noted.archive = this.state.archive === "Unarchive";
        notes = notes.replace(noteString,JSON.stringify(noted)) 
        // console.log(JSON.parse(notes))
        localStorage.setItem('notes',notes);
        // setTimeout(()=>{
        //     window.location = "/admin/notes";
        // },200); 
        console.log("open")
        this.props.close()
        console.log("closes")
    }

    onChangeArchive(e) {
        if (this.state.archive === "Archive") {
        this.setState({
            archive: "Unarchive"
        });}
        else{
            this.setState({
                archive: "Archive"
            });}    
    } 

    hide (e){
        e.preventDefault()
        this.props.close()
        // if(this.props.kid){
        //   console.log("tries?")
        //     this.setState({
        //       hide: true
        //     })
        //     var customer  =  JSON.parse(localStorage.getItem("crm")).filter(obj=> (obj._id === this.props.kid))[0];
        //     var customerString = JSON.stringify(customer)
        //     customer.hide = true;
        //     var crm = localStorage.getItem("crm")
        //     crm = crm.replace(customerString,JSON.stringify(customer))
        //     localStorage.setItem('crm',crm);
        //   }
        //   this.send()
          
      }

    onSubmit(e) {
        e.preventDefault();
        const note = {
            topic: this.state.topic,
            date: this.state.date,
            details: this.state.details,
            nextTil: this.state.nextTil,
            complete: this.state.complete,
            archive: this.state.archive === "Unarchive",
            owner: localStorage.getItem('token')
        };
        // if(localStorage.getItem("live")==="1"){
            if(navigator.onLine){
        axios.post(process.env.REACT_APP_API_URL+"/admin/notes/update/" + this.props.kid, note)
            // .then(res => setTimeout(() => { console.log(res.data); window.location = "/admin/notes"; }, 250));
            //  .then(res => setTimeout(() => { console.log(res.data); }, 250));
        }
                var noted =  JSON.parse(localStorage.getItem("notes")).filter(obj=> (obj._id === this.props.kid))[0];
                var noteString = JSON.stringify(noted)
                var notes = localStorage.getItem("notes")
                // console.log(JSON.parse(localStorage.getItem('notes')));
                noted.topic = this.state.topic;
                noted.date = this.state.date;
                noted.details = this.state.details;
                noted.nextTil = this.state.nextTil;
                noted.complete = this.state.complete;
                noted.archive = this.state.archive === "Unarchive";
                notes = notes.replace(noteString,JSON.stringify(noted)) 
                // console.log(JSON.parse(notes))
                localStorage.setItem('notes',notes);
                // setTimeout(()=>{
                //     window.location = "/admin/notes";
                // },200); 
                console.log("open")
                this.props.close()
                console.log("closes")
    }

    render() {
        return (
            <dialog id="additLog" className='modCRMlog' open>
            <div>
            <title>Edit Note</title>
            {/* <ReactNotifications
          onRef={ref => (this.n = ref)} // Required
          title="Note Updated" // Required
          onClick={event => this.handleClick(event)}
          body={this.state.topic}
          icon= {icon}
          
        //   timeout="10000"

        /> */}
        <button className="bt3" id="hidePerson" value="default" onClick={this.hide} >X</button>
                <form onSubmit={this.onSubmit}>
                <div className="form-group">
                        <div>
                            <DatePicker //npm install react-datepicker
                            className="edate"
                                selected={this.state.date}
                                onChange={this.onChangeDate}
                            />
                        </div>
                    </div>
                <div className="form-group">
                        
                        <ToggleButton
  value="check" 
sx={{
    "background-color": "transparent",
    "border-color": "#8542F2",
    "color": "#8542F2",
    "position": "absolute",
    "right": "5%",
    "top": "5%",
    "width": "1.5em !important",
"height": "1.5em !important",
    "border-width": "1px",
    '&:hover': {
        "background-color": "white",
        "border-color":"white"
      }
}}
onClick={this.onSubmit}
>
  <EditIcon fontSize="small" />
</ToggleButton>
                    </div>


                    <div className="form-group">
                        <textarea className= "topic" rows="2"
                                    type="text"
                                    value={this.state.topic}
                            onChange={this.onChangeTopic}
                                />
                    </div>
                    <div>
                        <label className="ocrlbl"> Occurence: </label>
                        <input
                            type="number"
                            className="ocurr"
                            value={this.state.nextTil}
                             onChange={this.onChangeNext}
                        />
                    </div>
                    <div className="form-group">
                    <textarea className= "detail" rows="4"
                                    type="text"
                                    placeholder="Details"
                                value={this.state.details}
                               onChange={this.onChangeDetails}
                                />
                    </div>
                    <div onClick={this.bringUp} style={{ cursor: 'pointer', userSelect: 'none' }}>
                    🕒
                    </div>
                    <ToggleButton
  value="check" 
sx={{
    "background-color": "transparent",
    "border-color": "grey",
    "color": "grey",
    "position": "absolute",
    "right": "5%",
    "margin-bottom": "5% !important",
    "width": "1.5em !important",
"height": "1.5em !important",
    "border-width": "1px",
    '&:hover': {
        "background-color": "white",
        "border-color":"white"
      }
}}
selected={this.state.archive=="Archive"}
onClick={this.onChangeArchive}
>
  <InventoryIcon fontSize="small" />
</ToggleButton>
                </form> 
            </div>
            </dialog>
        )
    }
}