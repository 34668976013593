import { Component } from 'react';
import axios from 'axios';

export default class EditExercise extends Component {
    constructor(props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeLevel = this.onChangeLevel.bind(this);
        this.onChangeNutrition = this.onChangeNutrition.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            name: '',
            nutrition: '',
            level: 0
        }
    }

    componentDidMount() {
        //right before displays component
        axios.get(process.env.REACT_APP_API_URL+"/allergy/" + this.props.match.params.id)
            .then(response => {
                this.setState({
                    name: response.data.name,
                    level: response.data.level,
                    nutrition: response.data.nutrition
                });
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }
    onChangeLevel(e) {
        this.setState({
            level: e.target.value
        });
    }

    onChangeNutrition(e) {
        this.setState({
            nutrition: e.target.value
        });
    }

    onSubmit(e) {
        e.preventDefault();
        const food = {
            name: this.state.name,
            nutrition: this.state.nutrition,
            level: this.state.level
        };
        axios.post(process.env.REACT_APP_API_URL+"/allergy/update/" + this.props.match.params.id, food)
            .then(res => setTimeout(() => { console.log(res.data); window.location = "/allergies/allergieslist"; }, 250));
        
    }

    render() {
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <input
                            type="submit"
                            value="Edit Allergy"
                            className="btn btn-primary"
                            onChange={this.onSubmit}
                        />
                    </div>
                    <div className="form-group">
                        <label>Name: </label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.name}
                            onChange={this.onChangeName}
                        />
                    </div>
                    <div className="form-group">
                        <label>Nutrition Group: </label>
                        <input
                            type="text"
                            className="form-control"
                            value={this.state.nutrition}
                            onChange={this.onChangeNutrition}
                        />
                    </div>
                    <div className="form-group">
                        <label>Level: </label>
                        <input
                            type="number"
                            className="form-control"
                            value={this.state.level}
                            onChange={this.onChangeLevel}
                        />
                    </div>
                                   </form>
            </div>
        )
    }
}