//import {BrowserRouter as Router, Route} from "react-router-dom";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"
// import Home from "./components/access.component";\
import { useEffect, useState } from "react";
import axios from "axios";

// import Home from "./pages/Home";
import Home from "./components/access.component"
import Login from "./pages/Login";
import Signup from "./pages/Signup";

//Admin
import useToken from './useToken';
import AllergyList from "./components/admin_fronteditors/allergies-list.component";
import EditAllergy from "./components/admin_fronteditors/edit-allergy.component";
import NotesList from "./components/admin_todo/notes-list.component";
import EditNote from "./components/admin_todo/edit-note.component";
import AdminRecipie from "./components/admin_fronteditors/admin-recipie.component";
import EditRecipie from "./components/admin_fronteditors/edit-recipie.component";
import BlogPost from "./components/admin_fronteditors/blogposts.component"

//CRM
import CRM from "./components/admin_crm/crm.component"
import CRML from "./components/admin_crm/crm-loc.component"
import CRMR from "./components/admin_crm/reach-user.component"

//Test
// import Scan from "./components/testing/scan.component"

function App() {  
  const token = useToken();
  const [user, setUser] = useState(null);

	// const getUser = async () => {
	// 	try {
	// 		const url = process.env.REACT_APP_API_URL+`/auth/login/success`;
	// 		const { data } = await axios.get(url, { withCredentials: true });
	// 		setUser(data.user._json);
	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// };

	// useEffect(() => {
	// 	getUser();
	// }, []);

  return (

    <Router>
        
      <Routes>  
      { token ? ( <>
		{/* <Route
					exact
					path="/"
					element={<Home user={user} />}
				/> */}
      <Route path="/" element={<Navigate to="/notes" />} />
      <Route path="/notes/edit/:id" exact element={<EditNote/>}/>
      <Route path="/notes" exact element={<NotesList/>}/>
      <Route path="/allergies/allergieslist" exact element={<AllergyList/>}/>
      <Route path="/allergies/edit/:id" exact element={<EditAllergy/>}/>
      <Route path="/recipies/" exact element={<AdminRecipie/>}/>
      <Route path="/recipies/edit/:id" exact element={<EditRecipie/>}/>  
      <Route path="/blogs/" exact element={<BlogPost/>}/>
      <Route path="/crm" exact element={<CRM/>}/>
      <Route path="/loci" exact element={<CRML/>}/>
      <Route path="/texter" exact element={<CRMR/>}/>
      </>
      ):
      <>
      <Route path="/" exact element={<Home/>}/>
			
				{/* <Route
					exact
					path="/login"
					element={user ? <Navigate to="/" /> : <Login />}
				/>
				<Route
					path="/signup"
					element={user ? <Navigate to="/" /> : <Signup />}
				/> */}
     </> }
    </Routes>
  
    </Router>
    
  );
}

export default App;
