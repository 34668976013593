import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./edit-recipie.css";
import { useParams } from 'react-router-dom';

const EditRecipie = () => {
  const { id } = useParams();
  const [recipe, setRecipe] = useState({
    name: '',
    description: '',
    outsideLink: [],
    videoLink: [],
    time: 0,
    hide: false,
    image: '',
    meal: 0,
    cuisine: '',
    steps: '',
    ingreds: '',
    serving: '',
    cal: 0,
    id: id
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL+`/recipie/recipie/${id}`);
        const data = response.data;

        setRecipe({
          name: data.name,
          meal: data.meal,
          cuisine: data.cuisine,
          hide: data.hide,
          image: data.image,
          time: data.time,
          steps: data.steps.join("; "),
          ingreds: data.ingreds.join("; "),
          outsideLink: data.outsideLinks.join("; "),
          videoLink: data.videoLinks.join("; "),
          serving: data.serving,
          cal: data.cal,
          description: data.description,
        });
      } catch (error) {
        console.error('Error fetching recipe:', error);
      }
    };

    fetchData();
  }, [id]);

  const imageView = () => {
    if (recipe.image && recipe.image.length > 0) {
      const base64Image = btoa(String.fromCharCode.apply(null, new Uint8Array(recipe.image)));

      return (
        <img
          src={`data:image/jpeg;base64,${base64Image}`}
          height="200"
          alt="recipe"
        />
      );
    }

    return null;
  };

  const handleInputChange = (field, value) => {
    setRecipe({
      ...recipe,
      [field]: value
    });
  };

  const hide = () => {
    setRecipe({
      ...recipe,
      hide: !recipe.hide
    });
  };

  const onDelete = () => {
    axios.get(process.env.REACT_APP_API_URL+`/recipie/delete/${id}`)
      .then(res => setTimeout(() => { console.log(res.data); window.location = "/admin/recipies"; }, 250));
  };

  const onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      const formData = new FormData();
      formData.append('file', img);
      axios.post(process.env.REACT_APP_API_URL+`/recipie/uploadphoto/adit/${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(res => setTimeout(() => { console.log(res.data); }, 250));

      console.log(img);
      setRecipe({
        ...recipe,
        image: URL.createObjectURL(img)
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const updatedRecipe = {
      owner: localStorage.getItem('token'),
      name: recipe.name,
      meal: recipe.meal,
      cuisine: recipe.cuisine,
      hide: recipe.hide,
      time: recipe.time,
      steps: recipe.steps.split("; "),
      ingreds: recipe.ingreds.split("; "),
      outsideLinks: recipe.outsideLink.split("; "),
      videoLinks: recipe.videoLink.split("; "),
      serving: recipe.serving,
      cal: recipe.cal,
      description: recipe.description,
    };

    axios.post(process.env.REACT_APP_API_URL+`/recipie/update/${id}`, updatedRecipe)
      .then(res => setTimeout(() => { window.location = "/recipies"; }, 250));
  };

  return (
    <div>
      <title>Edit Recipie</title>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          {/* your form JSX */}
          <input
            type="submit"
            value="Edit Recipie"
            className="editsubmit"
          />
          <div className="form-group">
            <textarea className="topic" rows="1"
              type="text"
              value={recipe.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
          </div>
          <div className="form-group">
            <div className="lblsep">Description :</div>
            <textarea className="description" rows="2"
              type="text"
              placeholder="Description"
              value={recipe.description}
              onChange={(e) => handleInputChange('description', e.target.value)}
            />
            <div className="lblsep">Cuisine :</div>
            <input className="rinput" type="text" value={recipe.cuisine}
              onChange={(e) => handleInputChange('cuisine', e.target.value)}
            />
            <div className="lblsep">Serving :</div>
            <input className="rinput" type="text" value={recipe.serving}
              onChange={(e) => handleInputChange('serving', e.target.value)}
            />
          </div>
          <label htmlFor="myfile"></label>
          <input id="myfile" className="bt1" type="file" name="myImage" onChange={onImageChange} style={{ 'background-color': 'gold', color: 'gold' }} />
          {imageView()}
          <div className="form-group">
            <div className="lblsep">Ingredients :</div>
            <textarea className="description" rows="3"
              type="text"
              placeholder="Ingredients"
              value={recipe.ingreds}
              onChange={(e) => handleInputChange('ingreds', e.target.value)}
            />
            <div className="lblsep">Instructions :</div>
            <textarea className="description" rows="3"
              type="text"
              placeholder="Instructions"
              value={recipe.steps}
              onChange={(e) => handleInputChange('steps', e.target.value)}
            />
          </div>
          <div className="lblsep">Links :</div>
          <textarea className="description" rows="3"
            type="text"
            placeholder="Links"
            value={recipe.outsideLink}
            onChange={(e) => handleInputChange('outsideLink', e.target.value)}
          />
          <div className="lblsep">Videos :</div>
          <textarea className="description" rows="3"
            type="text"
            placeholder="Videos"
            value={recipe.videoLink}
            onChange={(e) => handleInputChange('videoLink', e.target.value)}
          />
          <div>
            <label className="ocrlbl"> Time (min): </label>
            <input
              type="number"
              className="ocurr"
              value={recipe.time}
              onChange={(e) => handleInputChange('time', e.target.value)}
            />
          </div>
          <div>
            <label className="mealbl"> Meal :</label>
            <input
              type="number"
              className="meal"
              value={recipe.meal}
              onChange={(e) => handleInputChange('meal', e.target.value)}
            />
            <div className="meald">{recipe.meal.toString().replace("0", "All").replace("1", "Sauces/Preps").replace("2", "Apps").replace("3", "Meal").replace("4", "Dessert")}</div>
          </div>
          <div>
            <label className="calbl"> Cal :</label>
            <input
              type="number"
              className="cal"
              value={recipe.cal}
              onChange={(e) => handleInputChange('cal', e.target.value)}
            />
            <div className="meald">{recipe.cal.toString().replace("0", "All").replace("1", "Sauces/Preps").replace("2", "Apps").replace("3", "Meal").replace("4", "Dessert")}</div>
          </div>
          <button className="bt2 archive" onClick={hide}>{recipe.hide.toString().replace("false", "Hide").replace("true", "Unhide")}</button>
          <input type="button" style={{ color: "red", "border-color": "red" }} className="bt1 complete" value="Delete" onClick={onDelete} />
          {/* rest of your JSX */}
        </div>
      </form>
    </div>
  );
};
export default EditRecipie;

