import { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
// import icon from "../favicon.ico";
// import ReactNotifications from 'react-browser-notifications';

export default class EditPost extends Component {
    constructor(props) {
        super(props);
        this.onInfoChange = this.onInfoChange.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.delete = this.delete.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        
        // this.showNotifications = this.showNotifications.bind(this);
        // this.handleClick = this.handleClick.bind(this);
           
    this.state = {
      title: '',
      post: '',
      tag: [],
      comment: [],
      like: 0,
      date: new Date(),
      hide: false,
      image: '',
      owner: localStorage.getItem("token")
    }
    }

    componentDidMount() {
        //right before displays component
        if(this.props.kid){
          axios.get(process.env.REACT_APP_API_URL+"/blog/post/" + this.props.kid)
                .then(response => {
                  console.log(response)
                    this.setState({
                        title: response.data.title,
                        like: response.data.like,
                        date: new Date(response.data.date),
                        post: response.data.post,    
                        tag: response.data.tag,
                        comment: response.data.comment,
                        hide: response.data.hide,
                        image: response.data.image,
                        owner: response.data.owner
                    });
                    console.log(response.data.date);
                })
                .catch(function (error) {
                    console.log(error);
                })
          console.log("kid setup")
        }
    }

    onInfoChange(e) {
        e.preventDefault()
        if (e.target.className === "bt2 archive") {
          this.setState({
            hide: !this.state.hide
          });
        }
        else if (e.target.className === "detail") {
          this.setState({
            post: e.target.value
          });
        }
        else if (e.target.className === "ocurr") {
          this.setState({
            like: e.target.value
          });
        }
        else if (e.target.className === "topic") {
          this.setState({
            title: e.target.value
          });
        }
        else if (e.target.className === "bt1 url") {
            this.setState({
              image: e.target.value
            });
          }
        }
      
        onChangeDate(date) {
            this.setState({
                date: date
            });
        }

    delete(e) {
        axios.get(process.env.REACT_APP_API_URL+"/blog/delete/" + this.props.kid)
        .then(res => setTimeout(() => { console.log(res.data); window.location = "/blogs"; }, 250));
}

    onSubmit(e) {
        e.preventDefault();
        const blog = {
            title: this.state.title,
            date: this.state.date,
            owner: this.state.owner,
            post: this.state.post,
            like: this.state.like,
            hide: this.state.hide,
            tag: this.state.tag,
            album: "Personal",
            comment: this.state.comment,
            image: this.state.image
        };
        if (this.props.kid){
        axios.post(process.env.REACT_APP_API_URL+"/blog/update/" + this.props.kid, blog)
        .then(res => setTimeout(() => { console.log(res.data); this.props.close()}, 250));
        }
        else {
          axios.post(process.env.REACT_APP_API_URL+"/blog/add/", blog)
          .then(res => setTimeout(() => { console.log(res.data); this.props.close() }, 250));
        }
    }

    render() {
        return (
          <dialog id="additLog" className='modCRMlog' open>
           <title>Edit Blog</title>
           <button className="bt2" onClick={() => this.props.close()} style={{color: "#FFD600", borderColor:"#FFD600", position: "absolute", right:"2%"}} >X</button>
                <form onSubmit={this.onSubmit}>
                <div className="form-group">
                        <div>
                            <DatePicker //npm install react-datepicker
                            className="edate"
                                selected={this.state.date}
                                onChange={this.onChangeDate}
                            />
                        </div>
                    </div>
                <div className="form-group">
                        <input
                            type="submit"
                            value="Modify Blog"
                            className="editsubmit"
                            onChange={this.onSubmit}
                        />
                    </div>


                    <div className="form-group">
                        <textarea className= "topic" rows="1"
                                    type="text"
                                    value={this.state.title}
                            onChange={this.onInfoChange}
                                />
                    </div>
                    <img src={this.state.image}  height="200"  alt="blog"/>
                    <label className="bt1" for="myfile"></label>
                    <input id="myfile" className="bt1 url" type="text" name="myImage" value={this.state.image} onChange={this.onInfoChange} style={{'background-color':'gold',color:'white',width:300}}/>
                    <div>
                        <label className="ocrlbl"> Likes: </label>
                        <input
                            type="number"
                            className="ocurr"
                            value={this.state.like}
                            onChange={this.onInfoChange}
                        />
                    </div>
                    <div className="form-group">
                    <textarea className= "detail" rows="4"
                                    type="text"
                                    placeholder="Details"
                                value={this.state.post}
                               onChange={this.onInfoChange}
                                />
                    </div>
                    <button className="bt2 archive" onClick={(e) => { this.onInfoChange(e) }}>{this.state.hide.toString().replace("false","Hide").replace("true","Unhide")}</button>
                    <input type="button" style={{color:"red", "border-color":"red"}} className="bt1 complete" value="Delete" onClick={this.delete}/>
                </form> 
            </dialog>
        )
    }
}