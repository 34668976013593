import {Component} from 'react';
import axios from 'axios';
import "./access.css";

export default class Accesss extends Component {

    constructor(props){
        super(props);

        this.onChangePassword = this.onChangePassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state= {
            password: '',
        }
    }

    componentDidMount(){
        if(localStorage.getItem('token') !== null & localStorage.getItem('token') !=="60e725ad2b06463b74fe64a0") // temporarily doesnt allo geusts
            window.location = "/notes";
    }
         onChangePassword(e){
           this.setState({
               password: e.target.value
           });
       } 
    
       onSubmit(e){
        e.preventDefault();
        axios.post(process.env.REACT_APP_API_URL+"/login", {name: "adit", password: this.state.password})
            .then( token => setTimeout(() => {  localStorage.setItem('token', token.data); localStorage.setItem('notes', []); window.location = "/notes"; }, 250));
        }

        render(){
            return(
                <div>
                <title>Restricted</title>
                    <h3 className="accesstitle">Admin Access</h3>
                            <input type = "password"
                                required
                                className="form-control securetext"
                                value={this.state.password}
                                onChange= {this.onChangePassword}
                                autoFocus
                                />
                            <input type="submit" onClick={this.onSubmit} className="submit"/>
                </div>
            )
        }
}