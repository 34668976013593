import { Component } from 'react';
import axios from 'axios';
import Navbar from "../admin-navbar.component.js";
import "./crm.css";
import AddUser from './modifyuser.component';
import Interact from './interact.component.js';
import { ToggleButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import CakeIcon from '@mui/icons-material/Cake';
// import ReachUser from "./reach-user.component";

// import icon from "../favicon.ico";

const Customer = props => (
    <tr className="fullnote">
        {/* <td id={"b"+props.note.id} className="note" style={{color: props.namer(props.note._id), textShadow: "1px 1px 3px black"}} onClick={() => { props.view(props.note._id) }}>{props.note.firstname + " "+ props.note.lastname}</td> */}
        <td id={"b"+props.note.id} className="note" style={{color: props.namer(props.note._id), textShadow: "1px 1px 3px black"}} onClick={() => { props.view(props.note._id) }}>{props.note.firstname + " "+ props.note.lastname}</td>
        <td><select className='statusInput' onChange={(event) => props.onOptionChangeHandler(props.note._id,event.target.value)}>
                <option value="default">{props.note.ful}</option>
                <option style={{color: "green"}}>Complete</option>
                <option style={{color: "green"}}>RTS</option>
                <option style={{color: "yellow"}}>In Progress</option>
                <option style={{color: "yellow"}}>Find Gift</option>
                <option style={{color: "yellow"}}>Buy Gift</option>
                <option style={{color: "yellow"}}>Update Address</option>
                <option style={{color: "red"}}>N/A</option>
                <option style={{color: "red"}}>Start</option>
              </select></td>
        <td><span className="date">{(new Date(props.note.nextDay)).toLocaleString("en-US", { timeZone: 'UTC' }).substring(0, (new Date(props.note.nextDay)).toLocaleString().indexOf('/202')+5)}</span></td>
        <td style={{"text-align": "center"}}><ToggleButton
  value="check" 
sx={{
    "background-color": "black",
    "border-color": "#00ff00",
    "color": "white",
    "width": "1.5em !important",
    "height": "1.5em !important",
    "border-width": "1px",
    '&:hover': {
        "background-color": "white",
        "color": "#00ff00",
        "border-color":"white"
      }
}}
onClick={() => { props.next(props.note._id) }}
>
  <CheckIcon fontSize='small' />
</ToggleButton> <span> </span> 
<ToggleButton
  value="check" 
sx={{
    "background-color": "black",
    "border-color": "#8542F2",
    "color": "white",
    "width": "1.5em !important",
    "height": "1.5em !important",
    "border-width": "1px",
    '&:hover': {
        "background-color": "white",
        "color": "#8542F2",
        "border-color":"white"
      }
}}
onClick={() => props.edit(props.note._id)}
> 
  <EditIcon fontSize='small' />
</ToggleButton> <span> </span>
<ToggleButton
  value="check" 
sx={{
    "visibility": (props.note.smithai? "visible":"hidden"),
    "background-color": "black",
    "border-color": "#007bff",
    "color": "white",
    "width": "1.5em !important",
    "height": "1.5em !important",
    "border-width": "1px",
    '&:hover': {
        "background-color": "white",
        "color": "#007bff",
        "border-color":"white"
      }
}}
onClick={() => { props.wish(props.note._id) }}
>
  <CakeIcon fontSize='small' />
</ToggleButton></td>
    </tr>
)

export default class CustomerList extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.edit = this.edit.bind(this);
        this.reach = this.reach.bind(this);
        this.onChangeTopic = this.onChangeTopic.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onOptionChangeHandler = this.onOptionChangeHandler.bind(this)
        this.onOptionChangeType = this.onOptionChangeType.bind(this)
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitEnter = this.onSubmitEnter.bind(this);
        this.pullNotes = this.pullNotes.bind(this);
        this.add = this.add.bind(this);
        this.wish = this.wish.bind(this);
        this.close  = this.close.bind(this);
        this.namer = this.namer.bind(this);
        this.view = this.view.bind(this)
        this.compSort = this.compSort.bind(this);
        // this.showNotifications = this.showNotifications.bind(this);
        // this.handleClick = this.handleClick.bind(this);
        this.state = {
            topic: '',
            date: new Date(),
            selType: "",
            notes: JSON.parse("[]"),
            view: '',
            plan: '',
            planid: "",
            lastevent: "",
            types: [],
            last: new Date(),
            search: "",
            dialog: false,
            Iid: "",
            kid: "",
            vd: <></>,
        }

    }

    wish(id){
        var crm =  JSON.parse(localStorage.getItem("crm"))
        var customer  = crm.filter(obj=> (obj._id === id))[0];
        if(!customer.smithai)
            return
       
        if(navigator.onLine){
           
            var data = JSON.stringify({
                "data": {
                  "addresses": [
                    "+"+customer.c_code+customer.phone
                  ],
                  "message": "Happy Birthday "+customer.firstname+"!! 🎂🎉🎉",
                  "target_device_iden": "ujBTSZ7I3bMsjD0ZPqL70e"
                }
              });
              console.log("+"+customer.c_code+customer.phone)
              console.log(data)
            let config = {
                headers: {
                  "Access-Token": "o.qYTik5ZMShuzRz9pzLgHad8QBxUC4cLe",
                  'Content-Type': 'application/json'
                }
              }
            axios.post("https://api.pushbullet.com/v2/texts", data, config )
                .then(res => setTimeout(() => { 
                    console.log(res.data); 
                    // this.pullNotes(this.state.view);
                    this.reach(customer._id)
                    this.next(customer._id)
                    console.log("texted "+customer.firstname)
                }, 250))
                .catch((error) => {
                    console.log(error);
                    
               //     window.location = "/access";
                });}
    }

    namer(id) {
        var customer  =  JSON.parse(localStorage.getItem("crm")).filter(obj=> (obj._id === id))[0];
        if (!customer.bdaycard) {
            return "#032b43"
        }
        else if (customer.upgrade){
            return "gold"
        }
        else 
            return "white"
    }
    
    onChangeTopic(e) {
        this.setState({
            topic: e.target.value
        });
    }

    onChangeDate(date) {
        this.setState({
            date: date
        });
    }

    onOptionChangeHandler(id,val){
        var customer  =  JSON.parse(localStorage.getItem("crm")).filter(obj=> (obj._id === id))[0];
            var customerString = JSON.stringify(customer)
            customer.ful = val
            var crm = localStorage.getItem("crm")
            crm = crm.replace(customerString,JSON.stringify(customer))
            localStorage.setItem('crm',crm);
            this.setState({
                notes: JSON.parse(crm).sort(function(a, b) {
                    return (a["nextDay"] > b["nextDay"]) ? 1 : ((a["nextDay"] < b["nextDay"]) ? -1 : 0);
                  })
            }

            )
            if(navigator.onLine){
        
        axios.post(process.env.REACT_APP_API_URL+"/crm/update/" + id, customer)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                // this.pullNotes(this.state.view);
                 
            }, 250))
            .catch((error) => {
                console.log(error);
                
           //     window.location = "/access";
            });}

        // if(localStorage.getItem('live')==="0"){
        //     var off = JSON.parse(localStorage.getItem('off')).
        //     off.push(note)
        //     localStorage.setItem('off',JSON.stringify(off))
        //     console.log(off)
        // }
        this.notesList()
      }

    componentDidMount(){
        if(localStorage.getItem('crm')!==""){
            console.log("Cause ")
            this.setState({notes: JSON.parse(localStorage.getItem("crm")).sort(function(a, b) {
                return (a["nextDay"] > b["nextDay"]) ? 1 : ((a["nextDay"] < b["nextDay"]) ? -1 : 0);
              })})
        }
        this.pullNotes() 
        var customers = JSON.parse(localStorage.getItem("crm"));
        var types = ["All"];
        customers.forEach(element => {
            if (types.indexOf(element.type)===-1)
                types.push(element.type);
        });
        // var tags = ["All"];
        // customers.forEach(element => {
        //     if (tags.indexOf(element.tag)===-1)
        //         tags.push(element.tag);
        // });
        this.setState({
            types: types,
        })
    }

    close(){     
        this.setState({
            dialog: false,
            vd: Component,
        });
        this.setState({
            notes: JSON.parse(localStorage.getItem("crm")).sort(function(a, b) {
                return (a["nextDay"] > b["nextDay"]) ? 1 : ((a["nextDay"] < b["nextDay"]) ? -1 : 0);
              })
        })
    }

    add(){     
        this.setState({
            dialog: !this.state.dialog,
            vd: <AddUser close={this.close}/>
        })
    }

    view(id){
        console.log("view call: "+id)
        this.setState({
            dialog: true,
            // vd: <Interact kid={id} close={this.close}/>
            Iid: id
        })
    }

    edit(id){     
        this.setState({
            dialog: !this.state.dialog,
            vd: <AddUser kid={id} close={this.close}/>
        })
    }

    next(id) {
        // if(localStorage.getItem('live')==="1"){
        //  .filter(obj=> (obj._id === id));
                
        var customer  =  JSON.parse(localStorage.getItem("crm")).filter(obj=> (obj._id === id))[0];
            var customerString = JSON.stringify(customer)
            var nD = new Date(customer.nextDay)
            nD.setFullYear(nD.getFullYear()+1)
            customer.nextDay = nD
            if (customer.bdaycard && !customer.upgrade){
                customer.ful = "In Progress"
            }
            else {
                customer.ful = ""
            }
            var crm = localStorage.getItem("crm")
            crm = crm.replace(customerString,JSON.stringify(customer))
            localStorage.setItem('crm',crm);
            this.setState({
                notes: JSON.parse(crm).sort(function(a, b) {
                    return (a["nextDay"] > b["nextDay"]) ? 1 : ((a["nextDay"] < b["nextDay"]) ? -1 : 0);
                  })
            }

            )
            if(navigator.onLine){
        
        axios.post(process.env.REACT_APP_API_URL+"/crm/update/" + id, customer)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                // this.pullNotes(this.state.view);
                 
            }, 250))
            .catch((error) => {
                console.log(error);
                
           //     window.location = "/access";
            });}

        // if(localStorage.getItem('live')==="0"){
        //     var off = JSON.parse(localStorage.getItem('off')).
        //     off.push(note)
        //     localStorage.setItem('off',JSON.stringify(off))
        //     console.log(off)
        // }
        this.notesList()    
    }

    onChangePlan(e){
        this.setState({
            plan: e.target.value
        });    
    }

    onChangeofPlan(e){
        e.preventDefault();
        const note = {
            topic: this.state.plan,
            date: this.state.date,
            complete: false,
            display: true,
            owner: localStorage.getItem('token')
        };
        // localStorage.getItem('notes').match()
        // if(localStorage.getItem('live')==="1"){
            if(navigator.onLine){
        axios.post(process.env.REACT_APP_API_URL+"/admin/notes/update/" + this.state.planid, note)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                localStorage.setItem('live',"1")
                
             }, 250))
             .catch((error) => {
                console.log(error);
                localStorage.setItem('live',"0")
                
           //     window.location = "/access";
            });
        }
        var noted =  JSON.parse(localStorage.getItem("notes")).filter(obj=> (obj.display))[0];
        var noteString = JSON.stringify(noted)
        var notes = localStorage.getItem("notes")
        // console.log(JSON.parse(localStorage.getItem('notes')));
        // console.log(JSON.parse(notes))
        noted.topic = this.state.plan;
        notes = notes.replace(noteString,JSON.stringify(noted)) 
        // if(localStorage.getItem('live')==="0"){
        //     var off = JSON.parse(localStorage.getItem('off')).
        //     off.push(noted)
        //     localStorage.setItem('off',JSON.stringify(off))
        //     console.log(off)
        // }
        localStorage.setItem('notes',notes);
        setTimeout(()=>{
            document.getElementsByName(sessionStorage.getItem('view')).item(0).click();
            this.changeView()
        },500);     
    }

    onSubmit(e) {
        // if(localStorage.getItem('live')==="1"){
        if(navigator.onLine){
        e.preventDefault();
        const note = {
            topic: this.state.topic,
            date: this.state.date,
            archive: this.state.view==="Archive",
            owner: localStorage.getItem('token')
        }
    

        axios.post(process.env.REACT_APP_API_URL+"/admin/notes/add", note)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                this.pullNotes(this.state.view);
                localStorage.setItem('live',"1")
                
            }
                , 250))
                .catch((error) => {
                    console.log(error);
                    localStorage.setItem('live',"0")
                    
               //     window.location = "/access";
                });
            this.setState({
                    topic: '',
                    date: new Date()
                });
                setTimeout(()=>{
                    document.getElementsByName(sessionStorage.getItem('view')).item(0).click();
                    this.changeView();
                },500);  }
    }

    onSubmitEnter(event) {
    // if(localStorage.getItem('live')==="1"){
        if(navigator.onLine){
    if (event.key === "Enter") {
        const note = {
            topic: this.state.topic,
            date: this.state.date,
            archive: this.state.view==="Archive",
            owner: localStorage.getItem('token')
        }
        axios.post(process.env.REACT_APP_API_URL+"/admin/notes/add", note)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                this.pullNotes(this.state.view);
                
            }
                , 250))
                .catch((error) => {
                    console.log(error);
                    localStorage.setItem('live',"0")
                    
               //     window.location = "/access";
                });
            this.setState({
                    topic: '',
                    date: new Date()
                });
                setTimeout(()=>{
                    document.getElementsByName(sessionStorage.getItem('view')).item(0).click();
                    this.changeView()
                },500);  
            }
    }
    
}

    notesList() {
        if (localStorage.getItem("crm")===''){
            return
        }
        
        // console.log("Below is Notes:")
        // console.log(this.state.notes)
        
        return this.state.notes.map(customer => {
            return <Customer note={customer} onOptionChangeHandler={this.onOptionChangeHandler} view={this.view} next={this.next} wish={this.wish} close={this.close} edit={this.view} namer={this.namer} key={customer._id} />
        });
    }


    pullNotes(){
        console.log("Being called");
        axios.post(process.env.REACT_APP_API_URL+"/crm/", {owner: localStorage.getItem('token')})
        .then(response => {
            if (JSON.stringify(response.data)!==(localStorage.getItem("crm"))){  // forgot what this does
                console.log("Update")
                localStorage.setItem('crm', JSON.stringify(response.data));
                this.setState({ notes: response.data.sort(function(a, b) {
                    return (a["nextDay"] > b["nextDay"]) ? 1 : ((a["nextDay"] < b["nextDay"]) ? -1 : 0);
                  }) });
                // console.log(localStorage.getItem("notes"));
            }
            else {
                console.log("No Dif");
                this.setState({ notes: JSON.parse(localStorage.getItem("crm")).sort(function(a, b) {
                    return (a["nextDay"] > b["nextDay"]) ? 1 : ((a["nextDay"] < b["nextDay"]) ? -1 : 0);
                  })});
                // console.log(this.state.notes)
            } 
        })
        .catch((error) => {
            console.log(error);
            localStorage.setItem('live',"0")
       //     window.location = "/access";
        })};
        //     // console.log("Reach 0")
        //     // axios.post(process.env.REACT_APP_API_URL+"/admin/notes/"+views,{owner: localStorage.getItem('token')})
        //     //     .then(response => {
        //     //         console.log(localStorage.getItem("notes"))
        //     //         if ((localStorage.getItem("notes")==='') || response.data.topic!==JSON.parse(localStorage.getItem("notes"))){  // forgot what this does
                        
        //     //             console.log(response.data);
        //     //             this.setState({ notes: response.data });
                        
        //     //             localStorage.setItem('notes', JSON.stringify(response.data));
        //     //             console.log(localStorage.getItem("notes"));
        //     //         }
        //     //         else {
        //     //             console.log("Reach 2")
        //     //             console.log(response.data);
        //     //             this.setState({ notes: JSON.parse(localStorage.getItem("notes"))});
        //     //             console.log(localStorage.getItem("notes"));
        //     //         }   
        //     //     })
        //     //     .catch((error) => {
        //     //         console.log(error);
        //     //         // window.location = "/access";

        //         // });
        //     }
        //     else{
        //     axios.post(process.env.REACT_APP_API_URL+"/admin/notes/", {owner: localStorage.getItem('token')})
        //     .then(response => {
        //         if (response.data!==JSON.parse(localStorage.getItem("notes"))){  // forgot what this does
        //             console.log(response.data);
        //             this.setState({ notes: response.data });
        //             localStorage.setItem('notes', JSON.stringify(response.data));
        //             console.log(localStorage.getItem("notes"));
        //         }
        //         else {
        //             console.log(response.data);
        //             this.setState({ notes: JSON.parse(localStorage.getItem("notes"))});
        //             console.log(localStorage.getItem("notes"));
        //         } 
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //    //     window.location = "/access";
        //     });}
        // this.setState({ notes: JSON.parse(localStorage.getItem("notes")) });
    
        reach(id) {
            // if(localStorage.getItem('live')==="1"){
            //  .filter(obj=> (obj._id === id));
                    
            var customer  =  JSON.parse(localStorage.getItem("crm")).filter(obj=> (obj._id === id))[0];
                var customerString = JSON.stringify(customer)
                customer.lastReach = new Date();
                var crm = localStorage.getItem("crm")
                crm = crm.replace(customerString,JSON.stringify(customer))
                localStorage.setItem('crm',crm);
                this.setState({
                    notes: JSON.parse(crm)
                }
    
                )
                if(navigator.onLine){
            
            axios.post(process.env.REACT_APP_API_URL+"/crm/update/" + id, customer)
                .then(res => setTimeout(() => { 
                    console.log(res.data); 
                    // this.pullNotes(this.state.view);
                     
                }, 250))
                .catch((error) => {
                    console.log(error);
                    
               //     window.location = "/access";
                });}
    
            // if(localStorage.getItem('live')==="0"){
            //     var off = JSON.parse(localStorage.getItem('off')).
            //     off.push(note)
            //     localStorage.setItem('off',JSON.stringify(off))
            //     console.log(off)
            // }
            this.notesList()    
        }
        typeSelector() {
            return <select className='statusInput typer' onChange={(event) => this.onOptionChangeType(event.target.value,"typer")}> {this.state.types.sort().map(type => {
                    return <option>{type}</option>
                        })} </select>;
        }

        onOptionChangeType(selected,selector){
       
        if (selected==="All"){
            this.setState({  notes: this.compSort(JSON.parse(localStorage.getItem("crm"))) });}
                    else{
        if (selector==="typer"){
            this.setState({
                selType: selected
            });
        }
        
        
       
            var filt = JSON.parse(localStorage.getItem("crm")).filter((cus) => 
            {
                var val = ""; 
                if (selector==="typer"){
                   val = cus.type
                }
               
                if(val === selected){
                    return cus
              }
              else
                return null;
            });
            this.setState({ 
                notes: this.compSort(filt)
                    })
                }}
                
                compSort(crm){
                    // return crm.sort(function(a, b) {
                    //     return (a["next"] > b["next"]) ? 1 : ((a["next"] < b["next"]) ? -1 : 0);}).sort(function(a, b) {
                    // //         return (a["calc"] > b["calc"]) ? 1 : ((a["calc"] < b["calc"]) ? -1 : 0);}
                    // return crm.sort((a, b) => a["lastReach"]-b["lastReach"] || a["next"]-b["next"]);
                    return crm.sort(function(a, b) {
                        var valA = (new Date(a.lastReach)).setDate((new Date(a.lastReach)).getDate()+a.next)
                        var valB = (new Date(b.lastReach)).setDate((new Date(b.lastReach)).getDate()+b.next)
                        return (valA > valB) ? 1 : (valA < valB ? -1 : 0);})
                    // return ((a.next+a.lastReach) > (b.next+b.lastReach)) ? 1 : ((a.next+a.lastReach) < (b.next+b.lastReach) ? -1 : 0);})
                }

        onChangeSearch(e) {
            this.setState({
                search: e.target.value
            });
            if (localStorage.getItem("crm")===''){
                return
            }
            else {
                var filt = JSON.parse(localStorage.getItem("crm")).filter((cus) => 
                {
                    if(e.target.value === ""){
                        return cus
                  }
                  else if((cus.firstname+" "+cus.lastname).toLowerCase().includes(e.target.value.toLowerCase())){
                    return cus;
                  }
                });
                this.setState({ 
                    notes: filt.sort(function(a, b) {
                        return (a["nextDay"] > b["nextDay"]) ? 1 : ((a["nextDay"] < b["nextDay"]) ? -1 : 0);
                      })
                        })
                    }
    //         axios.post(process.env.REACT_APP_API_URL+"/crm/", {owner: localStorage.getItem('token')})
    //     .then(response => {
    //         if (JSON.stringify(response.data)!==(localStorage.getItem("crm"))){  // forgot what this does
    //             console.log("Update")
    //             localStorage.setItem('crm', JSON.stringify(response.data));
                
    //             this.setState({ notes: response.data });
                
    //             // console.log(localStorage.getItem("notes"));
    //         }
    //         else {
    //             console.log("No Dif");
    //             this.setState({ notes: JSON.parse(localStorage.getItem("crm"))});
    //             // console.log(this.state.notes)
    //         } 
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //         localStorage.setItem('live',"0")
    //    //     window.location = "/access";
    //     })
        }
    
    render() {
        return (
            <div className="container-fluid">
                <Navbar />
                <title>CRM</title>
                <h2 className="adBlogTitle" style={{color:"white",  paddingBottom: "1vh"}}>Birthday Card <a href="/loci" style={{color:"white"}}>Tracker </a></h2>
                <div className="adminsearchbar"> Search: <input className="adminsearchbar" type="search" value={this.state.search}
                               onChange={this.onChangeSearch}></input> </div>                
        {this.typeSelector()}
        {/* <dialog id="additLog" style={{background: "black", "border-color": "#FFD600", position:
        "fixed"}} open={this.state.dialog}> */}
        {this.state.dialog && <Interact kid={this.state.Iid} close={this.close} />}
        {this.state.vd}
        <div hidden={!this.state.dialog}>  
        {/* <AddUser kid={this.state.kid}/> */}
        </div>

{/* // </dialog> */}
        <div className="form-group">
                        <input
                            type="submit"
                            value="Addit"
                            className="aditsubmit"
                            onClick={() => this.add()}
                        />
                    </div>

                <table className="table table-sm table-responsive-sm table-hover" style={{border: ".25px white"}}>
                    <thead>
                        <tr className='tabar'>
                            <td>Name</td>
                            <td>Status</td>
                            <td>Next Birthday</td>
                            <td style={{textAlign:"center"}}>CTA</td>
                        </tr>
                    </thead>
                    <tbody>
                    {this.notesList()}
                   
                    </tbody>
                </table>
            </div>
        )
    }
}