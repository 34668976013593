import { Component } from 'react';
import axios from 'axios';
import Navbar from "../admin-navbar.component.js";
import "./crm.css";
import AddUser from './modifyuser.component';
import DatePicker from 'react-datepicker';
import Interact from './interact.component.js';
import { ToggleButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';

// import icon from "../favicon.ico";

const Customer = props => (
    <tr className="fullnote" style={{backgroundColor: props.color}}>
        <td className="note" style={{color: props.namer(props.note._id)}}  onClick={() => props.onInfoChange(props.note._id)}>{props.note.firstname + " "+ props.note.lastname}<span style={{color: (props.note.smithai? "green":"red")}}> ●</span></td>
        {/* <td><span className="date">{(new Date(props.note.calc)).toLocaleString("en-US", { timeZone: 'UTC' }).substring(0, (new Date(props.note.calc)).toLocaleString().indexOf('/202')+5)}</span></td> */}
        <td><span className="date">{(new Date( (new Date(props.note.lastReach)).setDate((new Date(props.note.lastReach)).getDate()+props.note.next))).toLocaleString("en-US", { timeZone: 'UTC' }).substring(0, 10).replace(",","")}</span></td>
        
        <td><button className="bt2" onClick={() => props.reach(props.note._id)}>Reached</button> | <ToggleButton
  value="check" 
sx={{
    "background-color": "black",
    "border-color": "#8542F2",
    "color": "white",
    "width": "1.5em !important",
    "height": "1.5em !important",
    "border-width": "1px",
    '&:hover': {
        "background-color": "white",
        "color": "#8542F2",
        "border-color":"white"
      }
}}
onClick={() => props.edit(props.note._id)}
> 
  <EditIcon fontSize='small' />
</ToggleButton> </td>
        {/* <td > <DatePicker //npm install react-datepicker
              className="lineDateInput shortDate"
              selected={new Date(props.note.lastReach)}
        
              onChange={(date) => props.onChangeLastReach(props.note._id, date)}
            /></td>
        <td> <input className="locInput"
            type="text"
            placeholder='Next'
            style={{width: 40}}
            defaultValue={props.note.next}
            onBlur={(event) => props.onChangeNext(props.note._id,event.target.value)}
          /> </td> */}
    </tr> 
)

// // const Group = props => (
// //     <div >
// //     <h2 className='areaTitle' style={{"textAlign":'left'}}> {props.area} </h2>
// //     <table style={{border: ".25px white"}}>
// //                     <thead>
// //                         <tr className='tabar'>
// //                             <td>Name</td>
// //                             <td>Next Date</td>
// //                             <td>Reached</td>
// //                             <td> </td>
// //                             <td>Push</td>
// //                         </tr>
// //                     </thead>
// //                     <tbody>
// //                     {props.customers}
// //                     </tbody>
// //                 </table></div>
// )

export default class ReachUser extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.reach = this.reach.bind(this);
        this.view = this.view.bind(this);
        this.onInfoChange = this.onInfoChange.bind(this);
        this.namer = this.namer.bind(this);
        this.onChangeTopic = this.onChangeTopic.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onOptionChangeHandler = this.onOptionChangeHandler.bind(this)
        this.onSubmit = this.onSubmit.bind(this);
        this.pullNotes = this.pullNotes.bind(this);
        this.add = this.add.bind(this);
        this.close  = this.close.bind(this);
        this.onChangeNext = this.onChangeNext.bind(this);
        this.onChangeLastReach = this.onChangeLastReach.bind(this);
        // this.showNotifications = this.showNotifications.bind(this);
        // this.handleClick = this.handleClick.bind(this);
        this.state = {
            topic: '',
            date: new Date(),
            notes: JSON.parse("[]"),
            view: '',
            plan: '',
            planid: "",
            lastevent: "",
            last: new Date(),
            search: "",
            dialog: false,
            kid: "",
            tags: [],
            types: [],
            selTag: "",
            selType: "",
            bulk: [],
            bulkId: [],
            vd: <></>,
            glitch: false,
        }

    }

    textThem(){
        var crm =  JSON.parse(localStorage.getItem("crm"))
        this.state.bulkId.forEach((id)=> {
        var customer  = crm.filter(obj=> (obj._id === id))[0];
        if(!customer.smithai)
            return
       
        if(navigator.onLine){
           
            var data = JSON.stringify({
                "data": {
                  "addresses": [
                    "+"+customer.c_code+customer.phone
                  ],
                  "message": this.state.topic.replace("@loc@",customer.tag).replace("@name@",customer.firstname),
                  "target_device_iden": "ujBTSZ7I3bMsjD0ZPqL70e"
                }
              });

              console.log(data)
            let config = {
                headers: {
                  "Access-Token": "o.qYTik5ZMShuzRz9pzLgHad8QBxUC4cLe",
                  'Content-Type': 'application/json'
                }
              }
            axios.post("https://api.pushbullet.com/v2/texts", data, config )
                .then(res => setTimeout(() => { 
                    console.log(res.data); 
                    // this.pullNotes(this.state.view);
                    this.reach(customer._id)
                    console.log("texted "+customer.firstname)
                }, 250))
                .catch((error) => {
                    console.log(error);
                    
               //     window.location = "/access";
                });}
        })
        this.setState({
            notes: this.compSort(JSON.parse(localStorage.getItem("crm"))),
            bulk: [],
            bulkId: [],
            topic: ""
        })
    }

    onInfoChange(id){
        var customer  =  JSON.parse(localStorage.getItem("crm")).filter(obj=> (obj._id === id))[0];
        if(!customer.smithai)
            return
        var bulkString = customer.firstname+" "+customer.lastname+" - "+customer.tag
        var index= this.state.bulk.indexOf(bulkString);
        if(index===-1){
        this.setState({
            bulkId: this.state.bulkId.concat(id),
            bulk: this.state.bulk.concat(bulkString)
        })
    }
    else{
        this.setState({
            bulkId: this.state.bulkId.filter((customer)=> (customer!==id) ),
            bulk:  this.state.bulk.filter((customer)=> (customer!==bulkString) )
        })
    }
        this.notesList();
    }

    typeSelector() {
        return <select className='statusInput typer' onChange={(event) => this.onOptionChangeHandler(event.target.value,"typer")}> {this.state.types.sort().map(type => {
                return <option>{type}</option>
                    })} </select>;
    }

    tagSelector() {
        return <select className='statusInput tagger' onChange={(event) => this.onOptionChangeHandler(event.target.value,"tagger")}> {this.state.tags.sort().map(tag => {
                return <option>{tag}</option>
                    })} </select>;
    }
    
    onChangeTopic(e) {
        this.setState({
            topic: e.target.value
        });
    }

    onChangeDate(date) {
        this.setState({
            date: date
        });
        
    }

    reach(id) {
        // if(localStorage.getItem('live')==="1"){
        //  .filter(obj=> (obj._id === id));
                
        var customer  =  JSON.parse(localStorage.getItem("crm")).filter(obj=> (obj._id === id))[0];
            var customerString = JSON.stringify(customer)
            customer.lastReach = new Date();
            var crm = localStorage.getItem("crm")
            crm = crm.replace(customerString,JSON.stringify(customer))
            localStorage.setItem('crm',crm);
            this.setState({
                notes: this.compSort(JSON.parse(crm))
            }

            )
            if(navigator.onLine){
        
        axios.post(process.env.REACT_APP_API_URL+"/crm/update/" + id, customer)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                // this.pullNotes(this.state.view);
                 
            }, 250))
            .catch((error) => {
                console.log(error);
                
           //     window.location = "/access";
            });}

        // if(localStorage.getItem('live')==="0"){
        //     var off = JSON.parse(localStorage.getItem('off')).
        //     off.push(note)
        //     localStorage.setItem('off',JSON.stringify(off))
        //     console.log(off)
        // }
        this.notesList()    
    }

    namer(id) {
        var customer  =  JSON.parse(localStorage.getItem("crm")).filter(obj=> (obj._id === id))[0];
        if (!customer.bdaycard) {
            return "blue"
        }
        else if (customer.upgrade){
            return "gold"
        }
        else 
            return "white"
    }

    onChangeNext(id,next) {
                    
        var customer  =  JSON.parse(localStorage.getItem("crm")).filter(obj=> (obj._id === id))[0];
            var customerString = JSON.stringify(customer)
            customer.next = next
            var crm = localStorage.getItem("crm")
            crm = crm.replace(customerString,JSON.stringify(customer))
            localStorage.setItem('crm',crm);
            this.setState({
                notes: this.compSort(JSON.parse(crm))
            }

            )
            if(navigator.onLine){
        
        axios.post(process.env.REACT_APP_API_URL+"/crm/update/" + id, customer)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                // this.pullNotes(this.state.view);
                 
            }, 250))
            .catch((error) => {console.log(error);});}
            this.notesList();
    }

    onChangeLastReach(id,reach) {
                    
        var customer  =  JSON.parse(localStorage.getItem("crm")).filter(obj=> (obj._id === id))[0];
            var customerString = JSON.stringify(customer)
            customer.lastReach = new Date(reach)
            var crm = localStorage.getItem("crm")
            crm = crm.replace(customerString,JSON.stringify(customer))
            localStorage.setItem('crm',crm);
            this.setState({
                notes: this.compSort(JSON.parse(crm))
            }

            )
            if(navigator.onLine){
        
        axios.post(process.env.REACT_APP_API_URL+"/crm/update/" + id, customer)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                // this.pullNotes(this.state.view);
                 
            }, 250))
            .catch((error) => {console.log(error);});}
            this.forceUpdate()
    }


    onOptionChangeHandler(selected,selector){
       
        if (selected==="All"){
            this.setState({  notes: this.compSort(JSON.parse(localStorage.getItem("crm"))) });}
                    else{
        if (selector==="typer"){
            this.setState({
                selType: selected
            });
        }
        else{
            this.setState({
                selTag: selected
            });
        }
        
       
            var filt = JSON.parse(localStorage.getItem("crm")).filter((cus) => 
            {
                var val = ""; 
                if (selector==="typer"){
                   val = cus.type
                }
                else{
                    val = cus.tag
                }
               
                if(val === selected){
                    return cus
              }
              else
                return null;
            });
            this.setState({ 
                notes: this.compSort(filt)
                    })
                }}
                
    

    componentDidMount(){
        if(localStorage.getItem('crm')!==""){
            console.log("Cause ")
            this.setState({
                notes: this.compSort(JSON.parse(localStorage.getItem("crm")))
            })
            
        }
        this.pullNotes() 
        
        var customers = JSON.parse(localStorage.getItem("crm"));
        var types = ["All"];
        customers.forEach(element => {
            if (types.indexOf(element.type)===-1)
                types.push(element.type);
        });
        var tags = ["All"];
        customers.forEach(element => {
            if (tags.indexOf(element.tag)===-1)
                tags.push(element.tag);
        });
        this.setState({
            types: types,
            tags: tags
        })
    }

    close(){     
        this.setState({
            dialog: false,
        });
        this.setState({
            notes: this.compSort(JSON.parse(localStorage.getItem("crm")))
        })
    }

    add(){     
        this.setState({
            dialog: !this.state.dialog,
            vd: <AddUser close={this.close}/>
        })
    }

    view(id){
        console.log("view call: "+id)
        this.setState({
            dialog: true,
            // vd: <Interact kid={id} close={this.close}/>
            Iid: id
        })
    }

    next(id) {
        // if(localStorage.getItem('live')==="1"){
        //  .filter(obj=> (obj._id === id));
                
        var customer  =  JSON.parse(localStorage.getItem("crm")).filter(obj=> (obj._id === id))[0];
            var customerString = JSON.stringify(customer)
            var nD = new Date(customer.lastReach)
            nD.setDate(nD.getDate()+customer.next)
            customer.lastReach = nD
            var crm = localStorage.getItem("crm")
            crm = crm.replace(customerString,JSON.stringify(customer))
            localStorage.setItem('crm',crm);
            this.setState({
                notes: this.compSort(JSON.parse(crm))
            }

            )
            if(navigator.onLine){
        
        axios.post(process.env.REACT_APP_API_URL+"/crm/update/" + id, customer)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                // this.pullNotes(this.state.view);
                 
            }, 250))
            .catch((error) => {
                console.log(error);
                
           //     window.location = "/access";
            });}

        // if(localStorage.getItem('live')==="0"){
        //     var off = JSON.parse(localStorage.getItem('off')).
        //     off.push(note)
        //     localStorage.setItem('off',JSON.stringify(off))
        //     console.log(off)
        // }
        this.notesList()    
    }

    onChangePlan(e){
        this.setState({
            plan: e.target.value
        });    
    }

    onChangeofPlan(e){
        e.preventDefault();
        const note = {
            topic: this.state.plan,
            date: this.state.date,
            complete: false,
            display: true,
            owner: localStorage.getItem('token')
        };
        // localStorage.getItem('notes').match()
        // if(localStorage.getItem('live')==="1"){
            if(navigator.onLine){
        axios.post(process.env.REACT_APP_API_URL+"/admin/notes/update/" + this.state.planid, note)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                
             }, 250))
             .catch((error) => {
                console.log(error);
                
           //     window.location = "/access";
            });
        }
        var noted =  JSON.parse(localStorage.getItem("notes")).filter(obj=> (obj.display))[0];
        var noteString = JSON.stringify(noted)
        var notes = localStorage.getItem("notes")
        // console.log(JSON.parse(localStorage.getItem('notes')));
        // console.log(JSON.parse(notes))
        noted.topic = this.state.plan;
        notes = notes.replace(noteString,JSON.stringify(noted))
        //     console.log(off)
        // }
        localStorage.setItem('notes',notes);
        setTimeout(()=>{
            document.getElementsByName(sessionStorage.getItem('view')).item(0).click();
        },500);     
    }

    onSubmit(e) {
        // if(localStorage.getItem('live')==="1"){
        if(navigator.onLine){
        e.preventDefault();
       this.textThem()
    }
    
}

    notesList() {
        if (localStorage.getItem("crm")===''){
            return
        }
        // console.log("Below is Notes:")
        // console.log(this.state.notes)
        // base.forEach(customer => { var calcdate = (new Date(customer.lastReach)).setDate((new Date(customer.lastReach)).getDate()+customer.next); console.log(new Date(calcdate).toDateString()); customer.calc = calcdate; console.log("Fixed" + JSON.stringify(customer));  return customer;})
        return this.state.notes  .filter(customer => customer.gift === true).map(customer => {
            return <Customer note={customer} onInfoChange={this.onInfoChange} onChangeNext={this.onChangeNext} namer={this.namer} color={( ((new Date( (new Date(customer.lastReach)).setDate((new Date(customer.lastReach)).getDate()+customer.next))) < (new Date()) ? "#347FC4" : 0))} onChangeLastReach={this.onChangeLastReach} next={this.next} reach={this.reach} edit={this.view} key={customer._id} />
        });
    }

   
    pullNotes(){
        if(navigator.onLine){
        console.log("Being called");
        axios.post(process.env.REACT_APP_API_URL+"/crm/", {owner: localStorage.getItem('token')})
        .then(response => {
            if (JSON.stringify(response.data)!==(localStorage.getItem("crm"))){  // forgot what this does
                console.log("Update")
                
                localStorage.setItem('crm', JSON.stringify(response.data));
                this.setState({ notes: this.compSort(response.data)});
                // console.log(localStorage.getItem("notes"));
            }
            else {
                console.log("No Dif");
                this.setState({
                    notes: this.compSort()
                // console.log(this.state.notes)
            }) 
        }})
        .catch((error) => {
            console.log(error);
       //     window.location = "/access";
        })}};
        //     // console.log("Reach 0")
        //     // axios.post(process.env.REACT_APP_API_URL+"/admin/notes/"+views,{owner: localStorage.getItem('token')})
        //     //     .then(response => {
        //     //         console.log(localStorage.getItem("notes"))
        //     //         if ((localStorage.getItem("notes")==='') || response.data.topic!==JSON.parse(localStorage.getItem("notes"))){  // forgot what this does
                        
        //     //             console.log(localStorage.getItem("notes"));
        //     //         }
        //     //         else {
        //     //             console.log("Reach 2")
        //     //             console.log(response.data);
        //     //             this.setState({ notes: JSON.parse(localStorage.getItem("notes"))});
        //     //             console.log(localStorage.getItem("notes"));
        //     //         }   
        //     //     })
        //     //     .catch((error) => {
        //     //         console.log(error);
        //     //         // window.location = "/access";

        //         // });
        //     }
        //     else{
        //     axios.post(process.env.REACT_APP_API_URL+"/admin/notes/", {owner: localStorage.getItem('token')})
        //     .then(response => {
        //         if (response.data!==JSON.parse(localSt
        //             console.log(localStorage.getItem("notes"));
        //         }
        //         else {
        //             console.log(response.data);
        //             this.setState({ notes: JSON.parse(localStorage.getItem("notes"))});
        //             console.log(localStorage.getItem("notes"));
        //         } 
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //    //     window.location = "/access";
        //     });}
        // this.setState({ notes: JSON.parse(localStorage.getItem("notes")) });
    
        compSort(crm){
            // return crm.sort(function(a, b) {
            //     return (a["next"] > b["next"]) ? 1 : ((a["next"] < b["next"]) ? -1 : 0);}).sort(function(a, b) {
            // //         return (a["calc"] > b["calc"]) ? 1 : ((a["calc"] < b["calc"]) ? -1 : 0);}
            // return crm.sort((a, b) => a["lastReach"]-b["lastReach"] || a["next"]-b["next"]);
            return crm.sort(function(a, b) {
                var valA = (new Date(a.lastReach)).setDate((new Date(a.lastReach)).getDate()+a.next)
                var valB = (new Date(b.lastReach)).setDate((new Date(b.lastReach)).getDate()+b.next)
                return (valA > valB) ? 1 : (valA < valB ? -1 : 0);})
            // return ((a.next+a.lastReach) > (b.next+b.lastReach)) ? 1 : ((a.next+a.lastReach) < (b.next+b.lastReach) ? -1 : 0);})
        }

        // locList() {
        //     if (localStorage.getItem("crm")===''){
        //         return
        //     }
            
    
        //     // console.log("Below is Notes:")
        //     // console.log(this.state.notes)
        //     var customers = JSON.parse(localStorage.getItem("crm"));
        //     var tags = [];
        //     customers.forEach(element => {
        //         if (tags.indexOf(element.tag)===-1)
        //             tags.push(element.tag);
        //     });
        //     return tags.sort().map(tag => {
        //         return <Group area={tag} customers={JSON.parse(localStorage.getItem("crm")).filter(obj=> obj.tag === tag).sort(function(a, b) {
        //             return (a["lastMet"] > b["lastMet"]) ? 1 : ((a["lastMet"] < b["lastMet"]) ? -1 : 0);
        //           }).map(customer => {
        //             return <Customer note={customer} onOptionChangeHandler={this.onOptionChangeHandler} next={this.next} edit={this.edit} onChangeLoci={this.onChangeLoci} key={customer._id} />
        //         })}/>
        //     });
    
        // }

        // affiliationList() {
            
        //     return types.sort().map(type => {
        //         return  <select className='statusInput' onChange={(event) => this.onOptionChangeHandler("bag",event.target.value)}>
        //         {{customers=this.compSort(JSON.parse(localStorage.getItem("crm")).filter(obj=> obj.type === type)).map(customer => {
        //             return <li note={customer} onOptionChangeHandler={this.onOptionChangeHandler} next={this.next} edit={this.edit} onChangeLoci={this.onChangeLoci} key={customer._id} />
        //         })}}}
        //         </select>
        //     });
    
        // }

        onChangeSearch(e) {
            this.setState({
                search: e.target.value
            });
            if (localStorage.getItem("crm")===''){
                return
            }
            else {
                var filt = JSON.parse(localStorage.getItem("crm")).filter((cus) => 
                {
                    if(e.target.value === ""){
                        return cus
                  }
                  else if((cus.firstname+" "+cus.lastname).toLowerCase().includes(e.target.value.toLowerCase())){
                    return cus;
                  }
                  else
                    return null;
                });
                this.setState({ 
                    notes: this.compSort(filt)
                        })
                    }
                
    //             this.setState({ notes: response.data });
                
    //             // console.log(localStorage.getItem("notes"));
    //         }
    //         else {
    //             console.log("No Dif");
    //             this.setState({ notes: JSON.parse(localStorage.getItem("crm"))});
    //             // console.log(this.state.notes)
    //         } 
    //     })
    //     .catch((error) => 
    //    //     window.location = "/access";
    //     })
        }
    
    render() {
        return (
            <div className="container-fluid">
                <title>CRM</title>
                <h2 className="adBlogTitle" style={{color:"white",  paddingBottom: "1vh"}}> Reacher</h2>
                <Navbar />
                <div className="adminsearchbar"> Search: <input className="adminsearchbar" type="search" value={this.state.search}
                               onChange={this.onChangeSearch}></input> </div>    
                {this.tagSelector()}{this.typeSelector()}
               
                <table className="table table-responsive" >
                    <tbody>
                    
                        <tr> 
                            <td>
                            <div style={{'color': 'black','marginBottom':"1.5%", "paddingTop":"", "font":"Raleway", "backgroundColor":"#FFD600","width":"fit-content"}} onClick={()=>(this.setState({bulk: [], bulkId: []}))}><strong>{this.state.bulk.join(", ")}</strong></div>
                            </td>
                      
                            <td>
                                <input className="newnote"
                                    type="text"
                                    value={this.state.topic}
                                    onChange={this.onChangeTopic}
                                />
                            </td>
                            
                        
                            <td>
                                <input
                                    type="submit"
                                    value="Send"
                                    className="bt2"
                                    onClick={this.onSubmit}
                                />
                            </td>

                        </tr>
                    </tbody>
                </table>
                {this.state.dialog && <Interact kid={this.state.Iid} close={this.close} />}
       

       

                <table className="table table-responsive" style={{border: ".25px white"}}>
                    <thead>
                        <tr className='tabar'>
                        <td>Name</td>
                            <td>Next Date</td>
                            <td>Reached</td>
                            {/* <td>Last Reach</td>
                            <td>Push</td> */}
                        </tr>
                    </thead>
                    <tbody>
                    {this.notesList()}
                   
                    </tbody>
                </table>

            </div>
        )
    }
}